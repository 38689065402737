import React, { useCallback, useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import "leaflet-draw";
import PopoverMenu from "../../components/PopoverMenu";
import { convertRadius, Unit } from "../../utils";
import { OpenInFull, OpenWith } from "@mui/icons-material";
import "leaflet-path-drag";
import { createRoot } from "react-dom/client";
import CustomButton from "../../components/CustomEditCircleButton";
import RenderButtons from "../../components/RenderButtons";
import { useHotkeys } from "react-hotkeys-hook";

interface CircleData {
  id: number;
  lat: number;
  lng: number;
  radius: number;
  colorCircle: string;
  borderCircle: string;
  unit: Unit;
}

const MapComponent: React.FC = () => {
  const mapRef = useRef<L.Map | null>(null);
  const [circles, setCircles] = useState<CircleData[]>([]);
  console.log(`🚀 ~ Teste ~ circles:`, circles);

  const [radiusValue, setRadiusValue] = useState<string>("7");
  const [colorCircle, setColorCircle] = useState("#aaaaaa");
  const [borderCircle, setBorderCircle] = useState("#000000");
  const [unit, setUnit] = useState<Unit>("kilometers");
  const [outputValue, setOutputValue] = useState<string>("");

  const [mode, setMode] = useState<"create" | "edit" | "delete" | "">("");

  // Função para criar e adicionar círculos diretamente ao mapa
  const handleMapClick = useCallback(
    (e: L.LeafletMouseEvent) => {
      if (mode !== "create") {
        console.log("Mode is not 'create', ignoring click");
        return;
      }

      const { lat, lng } = e.latlng;
      const radius = convertRadius(parseFloat(radiusValue), unit, "meters");

      if (mapRef.current) {
        // Cria e adiciona o círculo diretamente ao mapa
        const circle = L.circle([lat, lng], {
          radius,
          color: borderCircle,
          fillColor: colorCircle,
          fillOpacity: 0.3,
        }).addTo(mapRef.current);

        // Adiciona ao estado
        const newCircle: CircleData = {
          id: (circle as L.Circle & { _leaflet_id: number })._leaflet_id,
          radius,
          lat,
          lng,
          colorCircle,
          borderCircle,
          unit,
        };

        setCircles((prev) => [...prev, newCircle]);
      }
    },
    [borderCircle, colorCircle, mode, radiusValue, unit]
  );

  const [editingCircleId, setEditingCircleId] = useState<number | null>(null);

  const handleEditCircle = useCallback(
    (e: L.LeafletMouseEvent) => {
      if (mode !== "edit") {
        console.log("Mode is not 'edit', ignoring click");
        return;
      }

      // Garante que o mapRef não é null
      if (!mapRef.current) {
        console.error("Map is not initialized.");
        return;
      }

      // Armazena o mapa em uma variável local
      const map = mapRef.current;

      const clickedLatLng = e.latlng;

      // Encontra o círculo clicado
      const clickedCircle = circles.find(
        (circle) =>
          L.latLng(circle.lat, circle.lng).distanceTo(clickedLatLng) <=
          circle.radius
      );

      if (!clickedCircle) {
        console.log("No circle found at clicked location");
        return;
      }

      console.log("Editing circle:", clickedCircle);

      // Desativa a edição do círculo atual, se houver
      if (editingCircleId !== null) {
        map.eachLayer((layer) => {
          const circle = layer as L.Circle & L.EditableCircle;
          if (circle._leaflet_id === editingCircleId) {
            console.log(
              "Disabling edit mode for previous circle:",
              editingCircleId
            );
            circle.editing.disable();
          }
        });
      }

      // Ativa o modo de edição no círculo clicado
      map.eachLayer((layer) => {
        const circle = layer as L.Circle & L.EditableCircle;
        if (circle._leaflet_id === clickedCircle.id) {
          console.log("Enabling edit mode for circle:", clickedCircle.id);

          // Atualiza as variáveis de estado
          const radiusInUnit = convertRadius(
            circle.getRadius(),
            "meters",
            clickedCircle.unit
          );
          setUnit(clickedCircle.unit);
          setRadiusValue(radiusInUnit.toFixed(2));
          setColorCircle(circle.options.fillColor || "#aaaaaa");
          setBorderCircle(circle.options.color || "#000000");

          setEditingCircleId(clickedCircle.id);
          circle.editing.enable();

          // Customiza ícones de manipulação
          const editingLayer = map.getPane("markerPane");
          if (editingLayer) {
            const markers = Array.from(editingLayer.children) as HTMLElement[];

            markers.forEach((marker, index) => {
              marker.style.background = "none";
              marker.style.border = "none";
              marker.style.boxShadow = "none";
              marker.style.width = "auto";
              marker.style.height = "auto";
              marker.style.margin = "0";
              marker.style.padding = "0";

              while (marker.firstChild) {
                marker.removeChild(marker.firstChild);
              }

              const root = createRoot(marker);
              root.render(
                <CustomButton
                  icon={
                    index === 0 ? (
                      <OpenWith sx={{ color: "#3F3F3F", fontSize: 20 }} />
                    ) : (
                      <OpenInFull sx={{ color: "#3F3F3F", fontSize: 20 }} />
                    )
                  }
                />
              );
            });
          }

          // Adiciona lógica para arrastar o círculo
          let isDragging = false;

          circle.on("mousedown", (e: L.LeafletMouseEvent) => {
            isDragging = true;
            map.dragging.disable(); // Desabilita o arrasto do mapa

            // Oculta os botões durante o arrasto
            if (editingLayer) {
              const markers = Array.from(
                editingLayer.children
              ) as HTMLElement[];
              markers.forEach((marker) => {
                marker.style.opacity = "0";
              });
            }
          });

          map.on("mousemove", (moveEvent: L.LeafletMouseEvent) => {
            if (isDragging) {
              const { lat, lng } = moveEvent.latlng;

              // Atualiza a posição do círculo
              circle.setLatLng([lat, lng]);

              // Atualiza o estado com a nova posição
              setCircles((prev) =>
                prev.map((c) =>
                  c.id === clickedCircle.id ? { ...c, lat, lng } : c
                )
              );
            }
          });

          map.on("mouseup", () => {
            if (isDragging) {
              isDragging = false;
              map.dragging.enable(); // Reabilita o arrasto do mapa

              // Reexibe os botões após o arrasto
              if (editingLayer) {
                const markers = Array.from(
                  editingLayer.children
                ) as HTMLElement[];
                markers.forEach((marker) => {
                  marker.style.opacity = "1";
                });
              }

              const { lat, lng } = circle.getLatLng();
              console.log("Circle dragged to new position:", { lat, lng });
            }
          });

          // Atualiza o raio durante edição
          circle.on("edit", () => {
            const newRadius = circle.getRadius();
            const { lat, lng } = circle.getLatLng();

            setCircles((prev) =>
              prev.map((c) =>
                c.id === clickedCircle.id
                  ? { ...c, radius: newRadius, lat, lng }
                  : c
              )
            );

            const radiusInUnit = convertRadius(
              newRadius,
              "meters",
              clickedCircle.unit
            );
            setRadiusValue(radiusInUnit.toFixed(2));
          });

          // Finaliza edição
          circle.on("editend", () => {
            circle.editing.disable();
            setEditingCircleId(null);
            console.log("Edit mode disabled for circle");
          });
        }
      });
    },
    [circles, mode, editingCircleId]
  );

  useEffect(() => {
    if (!mapRef.current || editingCircleId === null) return;

    mapRef.current.eachLayer((layer) => {
      const circle = layer as L.Circle & L.EditableCircle;
      if (circle._leaflet_id === editingCircleId) {
        console.log("Updating circle styles and radius based on state");

        // Atualiza o estilo e o raio do círculo
        circle.setStyle({
          color: borderCircle,
          fillColor: colorCircle,
          fillOpacity: 0.3,
        });

        // Converte o raio da unidade do círculo para metros
        const radiusInMeters = convertRadius(
          parseFloat(radiusValue),
          unit,
          "meters"
        );
        circle.setRadius(radiusInMeters);
      }
    });
  }, [editingCircleId, radiusValue, colorCircle, borderCircle, unit]);

  useEffect(() => {
    if (!mapRef.current || editingCircleId === null) return;

    if (mode !== "edit") {
      console.log("Exiting edit mode, disabling circle editing");

      mapRef.current.eachLayer((layer) => {
        const circle = layer as L.Circle & L.EditableCircle;
        if (circle._leaflet_id === editingCircleId) {
          circle.editing.disable();
        }
      });

      setEditingCircleId(null); // Limpa o círculo em edição
    }
  }, [mode, editingCircleId]);

  const handleDeleteCircle = useCallback(
    (e: L.LeafletMouseEvent) => {
      if (mode !== "delete") {
        console.log("Mode is not 'delete', ignoring click");
        return;
      }

      if (!mapRef.current) {
        console.error("Map is not initialized.");
        return;
      }

      const clickedLatLng = e.latlng;

      // Find the clicked circle
      const clickedCircle = circles.find(
        (circle) =>
          L.latLng(circle.lat, circle.lng).distanceTo(clickedLatLng) <=
          circle.radius
      );

      if (!clickedCircle) {
        console.log("No circle found at clicked location");
        return;
      }

      console.log("Deleting circle:", clickedCircle);

      // Remove the circle from the map
      mapRef.current.eachLayer((layer) => {
        const circle = layer as L.Circle;
        if (
          (circle as L.Circle & { _leaflet_id: number })._leaflet_id ===
          clickedCircle.id
        ) {
          mapRef.current?.removeLayer(circle);
        }
      });

      // Update the state to exclude the deleted circle
      setCircles((prev) => prev.filter((c) => c.id !== clickedCircle.id));
    },
    [circles, mode]
  );

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    const map = mapRef.current;

    // Remove previous listeners
    map.off("click");
    if (mode === "create") {
      map.on("click", handleMapClick);
    } else if (mode === "edit") {
      map.on("click", handleEditCircle);
    } else if (mode === "delete") {
      map.on("click", handleDeleteCircle);
    }

    return () => {
      map.off("click");
    };
  }, [mode, handleMapClick, handleEditCircle, handleDeleteCircle]);

  const encodeCircles = (circles: CircleData[]): string => {
    const encodedCircles = circles
      .map(
        (circle) =>
          `%5B${circle.radius}%2C${circle.lat}%2C${
            circle.lng
          }%2C%22${encodeURIComponent(
            circle.colorCircle
          )}%22%2C%22${encodeURIComponent(circle.borderCircle)}%22%2C0.4%5D`
      )
      .join("%2C");
    return `https://www.mapdevelopers.com/draw-circle-tool.php?circles=%5B${encodedCircles}%5D`;
  };

  useEffect(() => {
    setOutputValue(encodeCircles(circles));
  }, [circles]);

  const decodeCircles = (encoded: string): CircleData[] => {
    // Captura todos os blocos de círculos entre [%5B e %5D]
    const match = encoded.match(/%5B(.*?)%5D/g);
    if (!match) return [];

    return match
      .map((circle) => {
        console.log(`🚀 Decoding Circle Block:`, circle);
        try {
          // Decodifica a string e remove brackets externos
          const cleaned = decodeURIComponent(circle)
            .replace(/^\[\[?|]]$/g, "") // Remove brackets externos, inclusive duplos
            .replace(/%22/g, '"'); // Decodifica aspas

          console.log("Cleaned Circle String:", cleaned);

          // Converte a string separada por vírgula para um array
          const decoded = cleaned.split(",");

          console.log("Decoded Circle Array:", decoded);

          if (decoded.length !== 6) {
            console.error("Invalid circle data length:", decoded);
            return null; // Dados inválidos
          }

          // Extração dos dados com validações adicionais
          const radius = parseFloat(decoded[0].trim());
          const lat = parseFloat(decoded[1].trim());
          const lng = parseFloat(decoded[2].trim());
          const colorCircle = decoded[3].replace(/"/g, "").trim().toLowerCase();
          const borderCircle = decoded[4]
            .replace(/"/g, "")
            .trim()
            .toLowerCase();

          // Validação dos dados
          if (
            isNaN(radius) ||
            isNaN(lat) ||
            isNaN(lng) ||
            !colorCircle ||
            !borderCircle
          ) {
            console.error("Invalid circle data after parsing:", {
              radius,
              lat,
              lng,
              colorCircle,
              borderCircle,
            });
            return null; // Ignora dados inválidos
          }

          // Retorna um círculo válido
          return {
            radius,
            lat,
            lng,
            colorCircle,
            borderCircle,
            unit: "miles", // Unidade padrão
          };
        } catch (error) {
          console.error("Error decoding circle data:", error);
          return null;
        }
      })
      .filter((circle): circle is CircleData => circle !== null); // Remove nulos
  };

  const handleDecodeAndCreateCircles = useCallback(() => {
    const decodedCircles = decodeCircles(outputValue);
    console.log(`🚀 Decoded Circles:`, decodedCircles);

    if (decodedCircles.length === 0) {
      console.warn("No valid circles found in payload.");
      return;
    }

    const map = mapRef.current;
    if (!map) {
      console.error("Map is not initialized.");
      return;
    }

    // Limpa todos os círculos do mapa
    map.eachLayer((layer) => {
      if (layer instanceof L.Circle) {
        map.removeLayer(layer); // Remove a camada do círculo
      }
    });

    // Limpa o estado de círculos
    setCircles([]);

    const updatedCircles: CircleData[] = [];

    // Cria os círculos no mapa e atribui IDs do Leaflet
    decodedCircles.forEach((circleData) => {
      const circle = L.circle([circleData.lat, circleData.lng], {
        radius: circleData.radius,
        color: circleData.borderCircle,
        fillColor: circleData.colorCircle,
        fillOpacity: 0.4,
      }).addTo(map);

      updatedCircles.push({
        ...circleData,
        id: (circle as L.Circle & { _leaflet_id: number })._leaflet_id,
      });
    });

    // Atualiza o estado com os círculos criados
    setCircles(updatedCircles);
  }, [outputValue, mapRef]);

  const handleCreateMode = () =>
    setMode((prev) => (prev === "create" ? "" : "create"));
  const handleEditMode = () =>
    setMode((prev) => (prev === "edit" ? "" : "edit"));
  const handleDeleteMode = () =>
    setMode((prev) => (prev === "delete" ? "" : "delete"));

  const handleDeleteAll = () => {
    //TODO SNACKBAR
    console.log("Deleting all circles...");

    if (!mapRef.current) {
      console.error("Map is not initialized.");
      return;
    }

    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Circle) {
        mapRef.current!.removeLayer(layer);
      }
    });
    setCircles([]);
  };

  useHotkeys("c", handleCreateMode, [handleCreateMode]);
  useHotkeys("e", handleEditMode, [handleEditMode]);
  useHotkeys("r", handleDeleteMode, [handleDeleteMode]);
  useHotkeys("shift+r", handleDeleteAll, [handleDeleteAll]);

  return (
    <>
      <RenderButtons
        mode={mode}
        handleCreateMode={handleCreateMode}
        handleEditMode={handleEditMode}
        handleDeleteMode={handleDeleteMode}
        handleDeleteAll={handleDeleteAll}
      />
      <MapContainer
        center={[37.6731312, -93.7296206]}
        zoom={5}
        style={{ width: "100%", height: "100vh" }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
      </MapContainer>
      <PopoverMenu
        mode={mode}
        radiusValue={radiusValue}
        setRadiusValue={setRadiusValue}
        colorCircle={colorCircle}
        setColorCircle={setColorCircle}
        borderCircle={borderCircle}
        setBorderCircle={setBorderCircle}
        unit={unit}
        setUnit={setUnit}
        outputValue={outputValue}
        setOutputValue={setOutputValue}
        loadingOutputValue={handleDecodeAndCreateCircles}
      />
    </>
  );
};

export default MapComponent;
