import React from "react";
import { Button, Stack } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";

interface ModeButtonGroupProps {
  buttons: {
    isSelected: boolean;
    onClick: () => void;
    icon: React.ReactElement<SvgIconProps>;
    isDependent?: boolean;
  }[];
  groupStyle?: React.CSSProperties;
}

const ModeButtonGroup: React.FC<ModeButtonGroupProps> = ({
  buttons,
  groupStyle,
}) => {
  const isParentSelected = buttons[0]?.isSelected;

  return (
    <Stack
      direction="row"
      sx={{
        ...groupStyle,
        overflow: "hidden",
        background: "#f9f9f9",
        alignItems: "center",
        borderRadius: "2px",
      }}
    >
      {buttons.map((button, index) => {
        if (button.isDependent && !isParentSelected) {
          return null;
        }

        return (
          <Button
            key={index}
            onClick={button.onClick}
            sx={{
              width: "30px",
              height: "30px",
              background: button.isSelected ? "#454545" : "white",
              minWidth: "0px",
              display: "flex",
              borderRadius: "0px",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                background: button.isSelected ? "#555555" : "#f0f0f0",
              },
            }}
          >
            {React.cloneElement(button.icon, {
              style: {
                color: button.isSelected ? "white" : "#636363",
                fontSize: "20px",
              },
            })}
          </Button>
        );
      })}
    </Stack>
  );
};

export default ModeButtonGroup;
