import React from "react";
import { Add, Edit, Delete, DeleteSweep } from "@mui/icons-material";
import ModeButtonGroup from "../StyledMenuButton";

interface RenderButtonsProps {
  mode: string;
  handleCreateMode: () => void;
  handleEditMode: () => void;
  handleDeleteMode: () => void;
  handleDeleteAll: () => void;
}

const RenderButtons: React.FC<RenderButtonsProps> = ({
  mode,
  handleCreateMode,
  handleEditMode,
  handleDeleteMode,
  handleDeleteAll,
}) => {
  const buttonStyles: React.CSSProperties = {
    borderRadius: "4px",
    position: "absolute",
    border: "2px solid rgba(0,0,0,0.3)",
    zIndex: 410,
    left: 10,
  };

  return (
    <>
      <div
        style={{
          ...buttonStyles,
          top: 150,
        }}
      >
        <ModeButtonGroup // TODO TOOLTIP
          buttons={[
            {
              isSelected: mode === "create",
              onClick: handleCreateMode,
              icon: <Add />,
            },
          ]}
        />
      </div>

      <div
        style={{
          ...buttonStyles,
          top: 188,
        }}
      >
        <ModeButtonGroup // TODO TOOLTIP
          buttons={[
            {
              isSelected: mode === "edit",
              onClick: handleEditMode,
              icon: <Edit />,
            },
          ]}
        />
      </div>

      <div
        style={{
          ...buttonStyles,
          top: 226,
        }}
      >
        <ModeButtonGroup // TODO TOOLTIP
          buttons={[
            {
              isSelected: mode === "delete",
              onClick: handleDeleteMode,
              icon: <Delete />,
            },
            {
              isSelected: false,
              onClick: handleDeleteAll,
              icon: <DeleteSweep />,
              isDependent: true,
            },
          ]}
        />
      </div>
    </>
  );
};

export default RenderButtons;
