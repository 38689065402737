import {
  ChevronLeft,
  ChevronRight,
  Close,
  CopyAll,
  Publish,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Column, Line } from "../../styles";
import { Unit } from "../../utils";
import { useHotkeys } from "react-hotkeys-hook";

interface Props {
  mode: string;
  radiusValue: string;
  setRadiusValue: React.Dispatch<React.SetStateAction<string>>;
  colorCircle: string;
  setColorCircle: React.Dispatch<React.SetStateAction<string>>;
  borderCircle: string;
  setBorderCircle: React.Dispatch<React.SetStateAction<string>>;
  unit: Unit;
  setUnit: React.Dispatch<React.SetStateAction<Unit>>;
  outputValue: string;
  setOutputValue: React.Dispatch<React.SetStateAction<string>>;
  loadingOutputValue: () => void;
}

const SimpleComponent: React.FC<Props> = ({
  mode,
  radiusValue,
  setRadiusValue,
  colorCircle,
  setColorCircle,
  borderCircle,
  setBorderCircle,
  unit,
  setUnit,
  outputValue,
  setOutputValue,
  loadingOutputValue,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseLeave = () => {
    // closeTimeout.current = setTimeout(() => {
    //   setIsOpen(false);
    // }, 2000);
  };

  const handleMouseEnter = () => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
      closeTimeout.current = null;
    }
  };

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  useHotkeys("m", toggleMenu, [toggleMenu]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setOutputValue(value);
  };

  const handleChangeUnit = (event: SelectChangeEvent<Unit>) => {
    setUnit(event.target.value as Unit);
  };

  const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^\d*[.,]?\d*$/.test(newValue)) {
      setRadiusValue(newValue);
    }
  };

  //   const processRadiusValue = () => {
  //     const numericValue = parseFloat(radiusValue.replace(",", "."));
  //     return numericValue;
  //   };

  const handleColorCircleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    let value = event.target.value;

    if (!value.startsWith("#")) {
      value = "#" + value.replace(/#/g, "");
    }

    if (/^#[0-9A-Fa-f]*$/.test(value) && value.length <= 7) {
      setState(value);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(outputValue).then(
      () => {
        //TODO SNACKBAR
        console.log("Texto copiado com sucesso!");
      },
      (err) => {
        //TODO SNACKBAR
        console.error("Erro ao copiar o texto: ", err);
      }
    );
  };

  const handleClearInput = () => {
    setOutputValue("");
    console.log("Texto colado com sucesso!");
  };

  const openDrawerComponent = () => (
    <div
      onClick={toggleMenu}
      style={{
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        transition: "right 0.5s cubic-bezier(0, 0, 0.2, 1)",
        height: "auto",
        padding: "6px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "0px 6px 6px 0px",
        backgroundColor: "#fff",
        border: "2px solid rgba(0,0,0,0.3)",
        position: "fixed",
        top: "330px",
        left: "-1px",
        zIndex: 420,
      }}
    >
      <Typography
        sx={{
          color: "#636363",
          fontWeight: "bold",
          fontSize: "0.9em",
          textWrap: "nowrap",
        }}
      >
        Menu
      </Typography>
      {isOpen ? (
        <ChevronLeft sx={{ color: "#636363" }} />
      ) : (
        <ChevronRight sx={{ color: "#636363" }} />
      )}
    </div>
  );

  const renderDrawerContent = () => {
    if (!isOpen) {
      return null;
    }

    return (
      <Box
        sx={{
          background: "#f9f9f9",
          boxShadow: "rgba(0, 0, 0, 0.2) 4px 0px 6px",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <Line
          style={{
            justifyContent: "space-between",
          }}
        >
          <div />
          <Typography variant="h6">Features Menu</Typography>
          <IconButton>
            <Close
              onClick={toggleMenu} // TODO TOOLTIP
            />
          </IconButton>
        </Line>
        <Divider sx={{ marginBottom: "15px", padding: "5px" }} />
        <Column style={{ gap: 20 }}>
          <Line style={{ gap: 10 }}>
            <Column style={{ display: "flex", alignItems: "start", gap: 5 }}>
              <Typography noWrap variant="subtitle2">
                Initial Radius:
              </Typography>

              <TextField
                key={`Changed-${radiusValue}`}
                aria-label="Radius"
                variant="outlined"
                size="small"
                name="radius"
                type="text"
                value={radiusValue}
                autoComplete="off"
                onChange={handleRadiusChange}
              />
            </Column>

            <Column style={{ display: "flex", alignItems: "start", gap: 5 }}>
              <Typography noWrap variant="subtitle2">
                Measure:
              </Typography>

              <Select
                labelId="unit-selector-label"
                id="unit-selector"
                variant="outlined"
                value={unit}
                onChange={handleChangeUnit}
                sx={{ height: "40px", width: "100%" }}
                disabled={mode === "edit"}
              >
                <MenuItem value="kilometers">🌍 Kilometers</MenuItem>
                <MenuItem value="miles">🛣️ Miles</MenuItem>
              </Select>
            </Column>

            <Column
              style={{
                display: "flex",
                alignItems: "start",
                gap: 5,
              }}
            >
              <Typography noWrap variant="subtitle2">
                Circle Color:
              </Typography>

              <TextField
                aria-label="Radius"
                variant="outlined"
                size="small"
                name="radius"
                type="text"
                autoComplete="off"
                value={colorCircle}
                onChange={(event) =>
                  handleColorCircleChange(event, setColorCircle)
                }
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: colorCircle,
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Column>

            <Column
              style={{
                display: "flex",
                alignItems: "start",
                gap: 5,
              }}
            >
              <Typography noWrap variant="subtitle2">
                Border Color:
              </Typography>

              <TextField
                aria-label="Radius"
                variant="outlined"
                size="small"
                name="radius"
                type="text"
                autoComplete="off"
                value={borderCircle}
                onChange={(event) =>
                  handleColorCircleChange(event, setBorderCircle)
                }
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: borderCircle,
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Column>
          </Line>

          <Line>
            <Column style={{ display: "flex", alignItems: "start", gap: 5 }}>
              <Typography noWrap variant="subtitle2">
                Output:
              </Typography>

              <Line style={{ gap: 10 }}>
                <TextField
                  aria-label="Output"
                  variant="outlined"
                  size="small"
                  name="Output"
                  type="text"
                  value={outputValue}
                  autoComplete="off"
                  multiline
                  rows={3}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                />

                <Column style={{ gap: 10, width: "5%" }}>
                  <IconButton
                    size="small"
                    onClick={loadingOutputValue} // TODO TOOLTIP
                  >
                    <Publish />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={handleCopyToClipboard} // TODO TOOLTIP
                  >
                    <CopyAll />
                  </IconButton>
                </Column>
              </Line>
            </Column>
          </Line>
        </Column>
      </Box>
    );
  };

  return (
    <Column
      style={{
        display: "flex",
        width: "auto",
      }}
    >
      {openDrawerComponent()}
      <Column
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Drawer
          variant="permanent"
          anchor="left"
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
          style={{
            width: isOpen ? "600px" : "0px",
            transition: "none",
            overflow: "hidden",
          }}
          sx={{
            ".MuiDrawer-paper": {
              top: "305px",
              left: "-2px",
              width: isOpen ? "600px" : "0px",
              overflowY: isOpen ? "auto" : "hidden",
              height: "auto",
              rowGap: "20px",
              borderRadius: "3px",
              border: "2px solid rgba(0,0,0,0.3)",
              zIndex: 425,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-center",
            },
          }}
        >
          {renderDrawerContent()}
        </Drawer>
      </Column>
    </Column>
  );
};

export default SimpleComponent;
