import React, { CSSProperties, ReactNode } from "react";

interface CustomButtonProps {
  icon: ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  icon,
  onClick,
  style,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: "24px",
        height: "24px",
        padding: 2,
        background: "#EFEFEF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: 6,
        border: "1px #636363 solid",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        display: "inline-flex",
        cursor: "pointer",
        marginLeft: "-10px",
        marginTop: " -10px",
        ...style,
      }}
    >
      {icon}
    </div>
  );
};

export default CustomButton;
